<template>
  <div class="text-preview-wrapper" :style="{ height: `${previewHeight}px` }">
    <div id="wrapper"></div>
    <div id="devicewrap">
      <div class="marvel-device iphone5s silver">
        <div class="top-bar"></div>
        <div class="sleep"></div>
        <div class="volume"></div>
        <div class="camera"></div>
        <div class="sensor"></div>
        <div class="speaker"></div>
        <div class="screen">
          <div class="carrier">
            <span class="left"> <i class="fa fa-signal"></i> Carrier </span>
            <span class="center">2:50pm</span>
            <span class="right">
              92%
              <i class="fa fa-stop"></i>
            </span>
          </div>
          <div class="text-title">
            <span class="left">
              <i class="fa fa-chevron-left"></i>
            </span>
            <span class="center">555-555-5555</span>
            <span class="right">Contact</span>
          </div>
          <div class="bubble bubble-left active">
            <span class="message-text">
              <p v-html="parsedMessageBody || 'Message Preview...'"></p>
              <p v-if="parsedPromo" v-html="parsedPromo"></p>
              Sent from {{ activeCompany.name }} <br />Reply STOP to opt-out
            </span>
          </div>
          <div
            class="bubble bubble-img bubble-left active p-0"
            v-for="(media, idx) in campaign.messageMedia"
            :key="idx"
          >
            <img class="rounded" :alt="'Loading Image'" :src="media" />
          </div>
        </div>
        <div class="home"></div>
        <div class="bottom-bar"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { templatize } from '@/lib/template'
import { createNamespacedHelpers } from 'vuex'
const CampaignModule = createNamespacedHelpers('campaign')
const CompanyModule = createNamespacedHelpers('company')
const UserModule = createNamespacedHelpers('user')

export default {
  name: 'MessagePreview',
  computed: {
    ...CampaignModule.mapState(['campaign', 'location']),
    ...CompanyModule.mapState(['activeCompany']),
    ...UserModule.mapState(['onlineUser']),
    parsedPromo() {
      if (!this.campaign.promo) return undefined
      return 'Redeem: <span class="text-primary">ovtn.io/redeem</span>'
    },
    parsedMessageBody() {
      let message = this.campaign.messageBody.replace(/\n/g, '<br>\n')
      return templatize({
        body: message,
        customer: { name: 'Jane Doe' },
      })
    },
    previewHeight() {
      let height = 465
      if (this.campaign.messageBody.length > 350) {
        height += this.campaign.messageBody.length
      }
      return Math.min(height, 533)
    },
  },
  methods: {
    ...CampaignModule.mapActions(['resetMessageBody', 'getDefaultLocation']),
  },
  mounted() {
    if (!this.campaign.messageBody) {
      this.resetMessageBody()
    }

    this.getDefaultLocation()
  },
}
</script>

<style scoped lang="scss">
.text-title .center {
  font-size: 13px;
}
.text-preview-wrapper {
  position: relative;
  top: 43px;
  overflow: hidden;
  .screen {
    overflow-y: scroll;
    height: 360px;
    .bubble {
      width: 85%;
    }

    .bubble-img {
      width: 42.5%;
    }
  }
}
</style>
