<template>
  <div class="media-dropdown">
    <button
      class="btn btn-white btn-sm mb-0"
      type="button"
      v-if="!data.length"
      @click="clickAddMedia"
    >
      <template v-if="loading">
        <b-spinner label="Loading..." small variant="secondary"></b-spinner>
      </template>
      <template v-else> <i class="fe fe-plus mr-2"></i>Image </template>
    </button>
    <b-dropdown class="hide-caret" size="sm" variant="primary" v-else>
      <template slot="button-content">
        <template v-if="loading">
          <b-spinner label="Loading..." small variant="white"></b-spinner>
        </template>
        <template v-else>
          <i class="fe fe-image"></i>
        </template>
      </template>
      <b-dropdown-text
        class="media-dropdown__item position-relative"
        v-for="(media, idx) in data"
        :key="idx"
      >
        <img
          class="media-dropdown__item-thumbnail align-middle"
          :alt="'Image ' + (idx + 1)"
          :src="media"
        />
        <button
          class="media-dropdown__item-delete btn btn-sm rounded-circle btn-white p-0 position-absolute text-center"
          type="button"
          @click="deleteMedia(idx)"
        >
          <i class="fe fe-x align-middle"></i>
        </button>
      </b-dropdown-text>
      <template v-if="data.length < maxFiles">
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item @click="clickAddMedia">Add Image</b-dropdown-item>
      </template>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: 'MediaDropdown',
  props: {
    data: {
      required: true,
      type: Array,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    maxFiles: {
      default: 1,
      type: Number,
    },
  },
  methods: {
    clickAddMedia() {
      this.$emit('clickAddMedia')
    },
    deleteMedia(idx) {
      this.$emit('deleteMedia', idx)
    },
  },
}
</script>

<style lang="scss" scoped>
.media-dropdown {
  position: relative;

  &__item {
    font-size: 14px;
    padding: 6px 24px 6px 24px;

    &-thumbnail {
      max-width: 108px;
      border-radius: 10px;
    }

    &-delete {
      height: 26px;
      width: 26px;
      position: absolute !important;
      right: 14px;
      top: 0;
    }
  }
}
</style>
