<template>
  <div>
    <!-- Smart Tags Dropdown -->
    <b-dropdown variant="white" size="sm" v-if="tags.length" class="hide-caret">
      <template slot="button-content">
        <span class="text-capitalize">
          <i class="fe fe-zap ml-1" style="position: relative; top: 1px"></i>
        </span>
      </template>
      <b-dropdown-item
        v-for="tag in tags"
        :key="tag.value"
        class="dropdown-item text-capitalize"
        @click="setTag(tag)"
      >
        {{ tag.name }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const SmartTagsModule = createNamespacedHelpers('smartTags')

export default {
  name: 'SmartTagsDropdown',
  computed: {
    ...SmartTagsModule.mapState(['tags']),
  },
  methods: {
    setTag(tag) {
      this.$emit('selected', tag)
    },
  },
}
</script>
