export default function imageCompress(file, size) {
  return new Promise((resolve) => {
    if (file.data.size < size) {
      return resolve(file)
    }

    const fileName = file.name
    const fileSize = file.data.size
    const fileType = file.type
    const reader = new FileReader()

    reader.readAsDataURL(file.data)

    reader.onload = (event) => {
      const img = new Image()

      img.src = event.target.result

      img.onload = () => {
        const elem = document.createElement('canvas')
        const ctx = elem.getContext('2d')
        const resizeFactor = size / (fileSize - 1)
        const width = img.width * resizeFactor
        const scaleFactor = width / img.width

        elem.width = width
        elem.height = img.height * scaleFactor

        ctx.drawImage(img, 0, 0, width, img.height * scaleFactor)

        elem.toBlob(
          async (blob) => {
            const newFile = new File([blob], fileName, {
              type: fileType,
              lastModified: Date.now(),
            })

            const result = { ...file, data: newFile }

            if (newFile.size > size) return resolve(await imageCompress(result, size))

            resolve(result)
          },
          fileType,
          0.95
        )
      }
    }
  })
}
