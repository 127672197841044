<template>
  <b-modal ref="modal" id="sendTestMessageModal" hide-footer hide-header body-class="p-0">
    <div class="modal-card card">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col">
            <h4 class="card-header-title" id="exampleModalCenterTitle">Send Test</h4>
          </div>
          <div class="col-auto">
            <button @click="close" type="button" class="close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body" style="max-height: 650px">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label> To: </label>
              <input
                v-model="phone"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errMessage }"
                placeholder="(000) 000-0000"
                v-mask="'(###) ###-####'"
                autocomplete="tel"
                @keyup.enter="validatePhone()"
                @keyup="hideAlert"
              />
              <div v-if="errMessage" class="invalid-feedback" role="alert">{{ errMessage }}</div>
              <span
                class="badge badge-pill badge-primary mt-3 ml-2"
                v-for="(recipient, index) in recipients"
                v-bind:key="index"
              >
                <span id="recipient">{{ recipient }}</span>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="removeReceipent(recipient)"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </span>
            </div>
          </div>
          <div class="col-12">
            <button
              @click="onSendTestMessage"
              :disabled="!formValid || isLoading"
              class="btn btn-primary col-12"
              :class="{ 'is-loading': isLoading }"
            >
              Send Test
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import CampaignService from '@/services/CampaignService'
import UserService from '@/services/UserService'
import { createNamespacedHelpers } from 'vuex'

const CampaignSetupsModule = createNamespacedHelpers('campaignSetups')
const CampaignModule = createNamespacedHelpers('campaign')
const CompanyModule = createNamespacedHelpers('company')
const UserModule = createNamespacedHelpers('user')
const CustomFilterModule = createNamespacedHelpers('customFilter')
const OrganizationModule = createNamespacedHelpers('organization')

export default {
  name: 'SendTestMessageModal',
  props: {
    source: {
      type: String,
      default: 'campaign',
    },
  },
  data: () => ({
    isLoading: false,
    errMessage: false,
    phone: '',
    recipients: [],
  }),
  computed: {
    ...CampaignSetupsModule.mapGetters(['selectCampaignSetup']),
    ...CampaignModule.mapState(['campaign', 'location']),
    ...CompanyModule.mapState(['activeCompany']),
    ...UserModule.mapState(['onlineUser']),
    ...CustomFilterModule.mapGetters(['selectCustomFilter']),
    ...OrganizationModule.mapGetters(['selectOrganization']),
    formValid() {
      return this.recipients.length > 0
    },
  },
  methods: {
    ...CampaignSetupsModule.mapActions(['sendCampaignSetupTestMessage']),
    async onSendTestMessage() {
      this.isLoading = true
      this.errMessage = ''
      try {
        await this.sendTestMessage()
        this.errMessage = ''
        this.close()
        this.$emit('sent')
      } catch (error) {
        this.errMessage = error.body?.data?.message || error.statusText
      }
      setTimeout(() => {
        this.isLoading = false
      }, 400)
    },
    async sendTestMessage() {
      if (this.source === 'campaign') {
        let bodyText = this.campaign.messageBody.replace(/\n/g, '<br>\n')
        await CampaignService.sendTestMessage({
          body: bodyText,
          company: this.activeCompany,
          user: this.onlineUser,
          location: this.location,
          recipients: this.recipients,
          customer: { name: 'Jane Doe' },
          media: this.campaign.messageMedia,
          promoId: this.campaign.promo,
        })
      } else if (this.source === 'campaignSetup') {
        await this.sendCampaignSetupTestMessage({
          phones: this.recipients, // this needs to be an array
          templateChunks: this.selectCampaignSetup.templateChunks,
          ...(this.activeCompany._id === 'ALL_COMPANIES'
            ? { organizationId: this.selectOrganization._id }
            : { companyId: this.activeCompany._id }),
          ...(this.selectCampaignSetup.promo ? { promoId: this.selectCampaignSetup.promo } : {}),
        })
      }
    },
    async validatePhone() {
      try {
        this.errMessage = ''
        const response = await UserService.checkValidPhone({
          phone: this.phone,
        })
        if (response) {
          this.recipients.push(this.phone)
          this.phone = ''
        }
      } catch (err) {
        this.errMessage = err.body.message
      }
    },
    hideAlert() {
      this.errMessage = false
    },
    removeReceipent(recipient) {
      this.recipients = this.recipients.filter((e) => e !== recipient)
    },
    close() {
      this.$refs.modal.hide()
      this.hideAlert()
      this.phone = ''
      this.recipients = []
      this.recipients.push(this.onlineUser.phone)
    },
  },
  mounted() {
    this.recipients.push(this.onlineUser.phone)
  },
}
</script>
<style scoped lang="scss">
.badge {
  .close {
    margin-left: 0.25rem;
    color: inherit;
    font-size: 100%;
    text-shadow: 0 1px 0 rgba(#000, 0.5);
  }
}
</style>
