<template>
  <div
    :class="['media-dropzone', isDragging || isDraggingOver ? 'media-dropzone--visible' : '']"
    @dragenter="handleComponentDragEnter"
    @dragover="handleComponentDragOver"
    @dragleave="handleComponentDragLeave"
    ref="dropzone"
  ></div>
</template>

<script>
import Uppy from '@uppy/core'
import UppyDragDrop from '@uppy/drag-drop'
import firebase from 'firebase/app'
import imageCompress from '@/lib/image/compress'

export default {
  name: 'Dropzone',
  props: {
    fileTypes: {
      default() {
        return ['image/*']
      },
      type: Array,
    },
    maxFiles: {
      default: 1,
      type: Number,
    },
    maxFileSize: {
      default: 1024000,
      type: Number,
    },
    maxTotalFileSize: {
      type: Number,
    },
    currentTotalFileSize: {
      type: Number,
    },
  },
  data: () => ({
    dragTimer: null,
    isDragging: false,
    isDraggingOver: false,
  }),
  methods: {
    async addMedia(file) {
      this.$emit('resetFileSize')
      this.$emit('resetUnsupportedFileType')
      const fileSize = parseFloat(file.data.size / Math.pow(1024, 1))
      const fileType = file.data.type

      if (fileSize > 450) {
        this.$emit('largeFileSize')
        return
      }

      if (!this.fileTypes.includes(fileType)) {
        this.$emit('unsupportedFileType')
        return
      }

      if (this.currentTotalFileSize + fileSize > this.maxTotalFileSize) {
        this.$notify({
          type: 'error',
          title: 'Total attachment size limit exceeded',
          text: `The total size of all attachments cannot exceed ${this.maxTotalFileSize} kb`,
          duration: 5000,
        })
        return
      }

      const { files } = window.event.target
      const filesLen = files.length
      const fileIdx = this.findFileIndex(file.data, files, filesLen)

      if (fileIdx === 0) {
        this.$emit('startLoading')
      }

      this.isDragging = false
      this.isDraggingOver = false

      const uploadFile = await imageCompress(file, this.$props.maxFileSize)

      const randomString = Math.random().toString(36).substring(7)
      const fileExtension = uploadFile.name.split('.').pop()
      const storageRef = firebase
        .storage()
        .ref()
        .child(`uploads/attachments/${randomString}.${fileExtension}`)

      const snap = await storageRef.put(uploadFile.data)
      const url = await snap.ref.getDownloadURL()

      this.$emit('addMedia', { url, fileSize })

      if (fileIdx === filesLen - 1) {
        this.$emit('endLoading')
      }
    },
    findFileIndex(file, files, filesLen) {
      for (let i = 0; i < filesLen; i++) {
        const iteratorFile = files[i]

        if (
          iteratorFile.lastModified === file.lastModified &&
          iteratorFile.name === file.name &&
          iteratorFile.size === file.size &&
          iteratorFile.type === file.type
        ) {
          return i
        }
      }

      return -1
    },
    handleComponentDragEnter() {
      this.isDraggingOver = true
    },
    handleComponentDragOver(e) {
      e.preventDefault()

      this.isDraggingOver = true
    },
    handleComponentDragLeave() {
      this.isDraggingOver = false
    },
    handleDocumentDragEnter() {
      this.isDragging = true
    },
    handleDocumentDragOver(e) {
      e.preventDefault()

      this.isDragging = true
    },
    handleDocumentDragLeave() {
      this.isDragging = false
    },
  },
  mounted() {
    const uppy = new Uppy({
      restrictions: {
        allowedFileTypes: this.$props.fileTypes,
        maxNumberOfFiles: this.$props.maxFiles,
      },
      onBeforeFileAdded: this.addMedia,
    })

    uppy.use(UppyDragDrop, {
      target: this.$refs.dropzone,
    })
  },
  destroyed() {
    // document.removeEventListener('dragenter', this.handleDocumentDragEnter)
    // document.removeEventListener('dragover', this.handleDocumentDragOver)
    // document.removeEventListener('dragleave', this.handleDocumentDragLeave)
  },
}
</script>

<style lang="scss">
.media-dropzone {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;

  &--visible {
    border: 2px dashed #2c7be5;
    background: white;
    z-index: 0;

    &::after {
      content: 'Drop files here';
      font-size: 20px;
      left: 0;
      line-height: 24px;
      position: absolute;
      right: 0;
      text-align: center;
      top: calc(50% - 12px);
    }
  }

  & .uppy {
    &-DragDrop {
      &-inner {
        display: none;
        visibility: hidden;
      }
    }
  }
}
</style>
